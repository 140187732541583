import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import HeroImg from '../../../assets/images/hero-index-main.png'
import Button from '../../button/button'
import StarredLink from '../../starred-link/starred-link'
import './hero.scss'

const query = graphql`
    query {
        contentfulMainPage {
            heroTitle
            heroSubtitle {
                heroSubtitle
            }
        }
    }
`

const Hero = () => {
    const data = useStaticQuery(query)

    return (
        <section className="index-hero">
            <div className="content-narrow">
                <div className="heading-container heading-container--index-hero">
                    <h1>{data.contentfulMainPage.heroTitle}</h1>
                    <p>
                        <Button
                            text="Get in touch"
                            href="/contact"
                            className="button--l-mobile"
                        />
                    </p>
                    <p>
                        <StarredLink text="We're hiring!" link="/careers" />
                    </p>
                </div>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt={data.contentfulMainPage.heroTitle} />
            </div>
        </section>
    )
}

export default Hero
