import PropTypes from 'prop-types'
import React from 'react'
import Layout from '../components/layout'
import Hero from '../components/index/hero/hero'
import Services from '../components/index/services'
import Focus from '../components/index/focus'
import Seo from '../components/seo/seo'

const Content = ({ location }) => {
    return (
        <Layout>
            <Seo
                title="South Lane - Full-service digital agency"
                desc="We help companies uncover opportunities,  create better connections with their customers, and deliver products that solve real-world problems."
                pathname={location.pathname}
            />
            <Hero />
            <Services />
            <Focus />
        </Layout>
    )
}

Content.propTypes = {
    location: PropTypes.shape({ pathname: PropTypes.string.isRequired })
        .isRequired,
}

export default Content
