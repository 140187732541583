import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import StarredLink from '../starred-link/starred-link'
import ListExpandable from '../list-expandable/list-expandable'

const query = graphql`
    query {
        contentfulMainPage {
            servicesTitle
            servicesSubtitle
            servicesItems {
                id
                title
                description
                shortDescription
                link
            }
        }
    }
`

const Services = () => {
    const data = useStaticQuery(query)

    return (
        <section className="index-services js-index-services">
            <div className="content-narrow">
                <div className="heading-container heading-container--index-services">
                    <h2
                        className="h4"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: data.contentfulMainPage.servicesSubtitle,
                        }}
                    />
                </div>
            </div>

            <ListExpandable list={data.contentfulMainPage.servicesItems} />

            <p className="align-center align-left-mobile">
                <StarredLink text="More about our services" link="/services" />
            </p>
        </section>
    )
}

export default Services
