// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React from 'react'
import ListExpandableItem from './list-expandable-item'
import './list-expandable.scss'

const Content = ({ list, className }) => {
    return (
        <div className={`list-expandable-items ${className || ''}`}>
            {list.map(item => {
                return (
                    <ListExpandableItem
                        key={item.title}
                        title={item.title}
                        shortDescription={item.shortDescription}
                        description={item.description}
                        link={item.link}
                    />
                )
            })}
        </div>
    )
}

Content.propTypes = {
    list: PropTypes.instanceOf(Array).isRequired,
    className: PropTypes.string,
}

Content.defaultProps = {
    className: '',
}

export default Content
