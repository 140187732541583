// noinspection ES6CheckImport
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'

const Content = ({ title, shortDescription, description, link }) => {
    return (
        <Link to={`/${link}`} className="list-expandable-item">
            <div className="list-expandable-item__content list-expandable-item__content--full">
                <div className="list-expandable-item__header">
                    <div className="h5">{title}</div>
                    <div className="list-expandable-item__trigger">
                        <i />
                    </div>
                </div>
                <div className="list-expandable-item__description">
                    {description.map(item => (
                        <p key={item}>{item}</p>
                    ))}
                </div>
            </div>
            <div className="list-expandable-item__content list-expandable-item__content--short">
                <div className="list-expandable-item__description">
                    <p>{shortDescription}</p>
                </div>
            </div>
        </Link>
    )
}

Content.propTypes = {
    title: PropTypes.string.isRequired,
    shortDescription: PropTypes.string.isRequired,
    description: PropTypes.instanceOf(Array).isRequired,
    link: PropTypes.string.isRequired,
}

export default Content
