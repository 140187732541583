import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import ListWithChecks from '../list-with-checks/list-with-checks'
import HeroImg from '../../assets/images/hero-what-we-focus-on.png'

const query = graphql`
    query {
        contentfulMainPage {
            focusTitle
            focusSubtitle
            focusItems {
                id
                title
                description
            }
        }
    }
`

const Content = () => {
    const data = useStaticQuery(query)

    return (
        <section className="index-focus">
            <div className="content-narrow">
                <div className="heading-container heading-container--focus">
                    <h2
                        className="h4"
                        // eslint-disable-next-line
                        dangerouslySetInnerHTML={{
                            __html: data.contentfulMainPage.focusSubtitle,
                        }}
                    />
                </div>
            </div>

            <div className="content-hero-image">
                <img src={HeroImg} alt={data.contentfulMainPage.focusTitle} />
            </div>

            <div className="content-narrow">
                <ListWithChecks list={data.contentfulMainPage.focusItems} />
            </div>
        </section>
    )
}

export default Content
